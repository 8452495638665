import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import PropTypes from 'prop-types'

import Layout from '../components/Layout'
import Button from '../components/Button'
import ProductPageTitle from '../components/ProductPageTitle'
import LoanSnapDifference from '../components/LoanSnapDifference'
import FeaturedIn from '../components/FeaturedIn'
import ReviewRoll from '../components/ReviewRoll'
import TextAndCTA from '../components/TextAndCTA'
import HelocSection from '../components/productPage/HelocSection'
import MortgageSection from '../components/productPage/MortgageSection'
import VASection from '../components/productPage/VASection'
import RefinanceSection from '../components/productPage/RefinanceSection'
import _ from 'lodash'

import arrow from '../img/icons/arrows_REFI_mobile.svg'
import { themeStyles } from '../utils/theme'

const styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: `${themeStyles.elementMargin.md} 0`,
    ...themeStyles.contentWidth,
    maxWidth: '800px',
    ...themeStyles.textPadding,
    textAlign: 'center',
  },
  center: {
    textAlign: 'center',
  },
  arrow: {
    maxHeight: '170px',
  }
}

export const ProductPageTemplate = ({ data }) => {
  const frontmatter = data.markdownRemark.frontmatter
  const {
    productTypeKey,
    productTypeLabel,
    title,
    description,
    featuredImage,
    targetUrl,
    buttonText,
    differenceTitle,
    differences,
    productSectionHeader,
    productSectionDescription1,
    productSectionDescription2,
    explainers,
    finalCTA,
    showHeaderLinks,
    showMandatoryLinksOnly,
  } = frontmatter
  const isTikTokHeloc = _.includes(productTypeKey, 'tiktok')
  return (
    <Layout showLinks={showHeaderLinks} metadataTitle={`LoanSnap - ${productTypeLabel}`} metadataDescription={title} showMandatoryLinksOnly={showMandatoryLinksOnly}>
      <ProductPageTitle
        productTypeLabel={productTypeLabel}
        title={title}
        description={description}
        fluidImage={get(featuredImage, 'childImageSharp.gatsbyImageData')}
        link={targetUrl}
        isTikTokHeloc={isTikTokHeloc}
      />
      <LoanSnapDifference
        typeName={productTypeLabel}
        title={differenceTitle}
        items={differences}
      />
      <FeaturedIn />
      <ReviewRoll />
      <div css={{...themeStyles.contentWidth, marginTop: '30px', marginBottom: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Button
          text={buttonText}
          href={targetUrl}
        />
      </div>
      <div css={styles.mainContainer}>
        {_.includes(productTypeKey, 'heloc') && <HelocSection {...{productSectionHeader, productSectionDescription1, productSectionDescription2, explainers}}/>}
        {productTypeKey === 'mortgage' && <MortgageSection {...{productSectionHeader, productSectionDescription1, explainers}}/>}
        {productTypeKey === 'va' && <VASection {...{productSectionHeader, productSectionDescription1, explainers}}/>}
        {productTypeKey === 'refinance' && <RefinanceSection {...{productSectionHeader, explainers}}/>}
        {productTypeKey === 'loanflow' && <RefinanceSection {...{productSectionHeader, explainers}}/>}
        {productTypeKey !== 'refinance' && productTypeKey !== 'loanflow' && <img src={arrow} css={styles.arrow} alt=''/>}
        <TextAndCTA
          text={finalCTA.text}
          buttonText={buttonText}
          link={targetUrl}
        />
        <div css={themeStyles.sectionTopMargin} />
      </div>
    </Layout>
  )
}

ProductPageTemplate.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        productTypeLabel: PropTypes.string,
        productTypeKey: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        featuredImage: PropTypes.object,
        targetUrl: PropTypes.string,
        buttonText: PropTypes.string,
        differenceTitle: PropTypes.string,
        differences: PropTypes.arrayOf(
          PropTypes.shape({
            text: PropTypes.string,
            subText: PropTypes.string,
            image: PropTypes.string,
          })
        ),
        productSectionHeader: PropTypes.string,
        productSectionDescription1: PropTypes.string,
        productSectionDescription2: PropTypes.string,
        explainers: PropTypes.arrayOf(
          PropTypes.shape({
            text: PropTypes.string,
            image: PropTypes.object,
          })
        ),
        finalCTA: PropTypes.shape({
          text: PropTypes.string,
          buttonText: PropTypes.string,
        }),
        showHeaderLinks: PropTypes.bool,
        showMandatoryLinksOnly: PropTypes.bool,
      })
    })
  }),
}

export default ProductPageTemplate

export const pageQuery = graphql`
  query ProductPageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      htmlAst
      frontmatter {
        productTypeLabel
        productTypeKey
        title
        description
        featuredImage {
          childImageSharp {
            gatsbyImageData(width: 500, quality: 100, layout: CONSTRAINED)
          }
        }
        targetUrl
        buttonText
        differenceTitle
        differences {
          text
          subText
        }
        productSectionHeader
        productSectionDescription1
        productSectionDescription2
        explainers {
          text
          subText
        }
        finalCTA {
          text
          buttonText
        }
        showHeaderLinks
        showMandatoryLinksOnly
      }
    }
  }
`
