import React from 'react'
import Button from '../components/Button'
import { themeStyles, colors, presets } from '../utils/theme'
import heroArrow from '../img/heroarrow_tip.svg'
import { GatsbyImage } from "gatsby-plugin-image";

const styles = {
  outer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    position: 'relative',
    height: 'inherit',
    [presets.Tablet]: {
      height: '400px',
    },
  },
  mask: {
    height: '400px',
    marginBottom: 0,
    position: 'absolute',
    left: 0,
    display: 'none',
    [presets.Tablet]: {
      display: 'block',
    },
    zIndex: 1,
  },
  imageHolder: {
    height: '398px',
    position: 'relative',
    flex: 1,
    backgroundRepeat: 'no-repeat',
    zIndex: -1,
    display: 'none',
    [presets.Tablet]: {
      display: 'flex',
    },
    overflow: 'hidden',
  },
  textHolder: {
    display: 'flex',
    flex: 1,
    minHeight: '398px',
    flexDirection: 'column',
    ...themeStyles.textPadding,
    paddingBottom: 0,
    width: '100%',
    [presets.Tablet]: {
      maxWidth: '600px',
    }
  },
  background: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    zIndex: -1,
  },
  white: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  image: {
    objectFit: 'cover',
    width: '100%',
    margin: 0,
  },
  tiktokImage: {
    maxWidth: '30rem',
  },
  buttonContainer: {
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'row',
  },
}

const renderImage = (isTikTokPage, image, flipImage) => {
  const helocImage = (
    <div css={styles.imageHolder}>
      <img src={heroArrow} css={styles.mask} alt=''/>
      <GatsbyImage image={image} alt='Hero' css={[styles.image, flipImage ? {transform: 'scaleX(-1)'} : {}]} />
    </div>
  )
  const tikTokImage = (
    <div css={styles.imageHolder}>
      <GatsbyImage image={image} alt='Hero' css={[styles.image, styles.tiktokImage, flipImage ? {transform: 'scaleX(-1)'} : {}]} />
    </div>
  )
  return (
    isTikTokPage ? tikTokImage : helocImage
  )
}


const ProductPageTitle = ({ productTypeLabel, title, description, fluidImage, flipImage, link, isTikTokHeloc }) => {
  return (
    <div css={styles.outer}>
      <div css={styles.background}>
        <div css={styles.white} />
        {renderImage(isTikTokHeloc, fluidImage, flipImage)}
      </div>
      <div css={themeStyles.contentWidth}>
        <div css={styles.textHolder}>
          <h2 css={{ margin: '30px 0 0', color: colors.green }}>
            {productTypeLabel}
          </h2>
          <h1 css={{ fontWeight: 100, fontSize: '2.4em', color: colors.grey, margin: '20px 0 20px -2px' }}>
            {title}
          </h1>
          <p>
            {description}
          </p>
          <div css={styles.buttonContainer}>
            <Button
              href={link || 'https://secure.goloansnap.com'}
              text="Get Started"
              analyticsTargetName='Product Page Hero Get Started'
            />
          </div>
        </div>
        <div css={styles.white}/>
      </div>
    </div>
  )
}

export default ProductPageTitle