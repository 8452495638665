import React from 'react'
import { themeStyles, colors, presets } from '../../utils/theme'
import badges from '../../img/icons/badges.svg'
import home from '../../img/icons/home.svg'
import doc from '../../img/icons/doc.svg'

const styles = {
  imageWithTextHolder: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    color: colors.darkGreen,
    marginBottom: '20px',
  },
  iconRow: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: '100%',
    marginBottom: '30px',
    [presets.Tablet]: {
      flexDirection: 'row',
    }
  },
  iconHolder: {
    backgroundColor: colors.tan,
    width: '200px',
    height: '180px',
    marginLeft: '20px',
    marginRight: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderRadius: '10px',
    marginBottom: '20px',
    [presets.Tablet]: {
      marginBottom: 0,
    }
  },
  icon: {
    height: '80px',
    marginTop: '25px',
    marginBottom: '20px',
  },
  iconText: {
    color: colors.blueGreen,
    fontWeight: 600,
  },
}

const mortgageExplainersIcons = [ badges, home, doc ]

const MortgageSection = ({productSectionHeader, productSectionDescription1, explainers}) => (
  <React.Fragment>
    <h2 css={themeStyles.sectionHeader}>
      {productSectionHeader}
    </h2>
    <p>
      {productSectionDescription1}
    </p>
    { explainers && <div css={styles.iconRow}>
      {explainers.map((item, index) => (
        <div css={styles.iconHolder} key={index}>
          <img src={mortgageExplainersIcons[index]} css={styles.icon} alt=''/>
          <p css={styles.iconText}>
            {item.text}
          </p>
        </div>
      ))}
    </div>
    }
    </React.Fragment>
)

export default MortgageSection