import React from 'react'
import { themeStyles, colors } from '../utils/theme'
import creditCard from '../img/CreditCard_Option2_50673758.png'
import phone2 from '../img/icons/phone.png'
import vaLoanExpert from '../img/va-loan-expert.png'
import mortgageExpert from '../img/va-loans-2.png'
import helocExpert from '../img/heloc-expert-3.png'
import refiExpert from '../img/refi-expert-1.png'
import fast from '../img/fast.png'
import money from '../img/money.png'
import boss from '../img/boss.png'

const loByLoanType = {
  'Refinance': "refiExpert",
  'HELOC': "helocExpert",
  'Mortgage': "mortgageExpert",
  'VA loan': "vaLoanExpert",
}

const styles = {
  outer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    ...themeStyles.sectionTopMargin,
    ...themeStyles.contentWidth,
    ...themeStyles.textPadding,
  },
  items: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    textAlign: 'left',
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: '280px',
  },
  image: {
    width: '250px',
    height: '250px',
  },
  spacer: {
    display: 'flex',
    flex: 1,
  },
  title: {
    color: colors.blueGreen,
  },
}

const Item = ({ title, description, image, index }) => (
  <div css={styles.itemContainer}>
    <h3 css={styles.title}>
      {title}
    </h3>
    <p css={{ fontSize: '15px' }}>
      {description}
    </p>
    <div css={styles.spacer}/>
    <img
      src={image}
      css={styles.image}
      alt=''
    />
  </div>
)

const LoanSnapDifference = ({ title, typeName, items }) => {
  const images = {
    'fast': fast,
    'money': money,
    'boss': boss,
    'creditCard': creditCard,
    'phone2': phone2,
    'vaLoanExpert': vaLoanExpert,
    'mortgageExpert': mortgageExpert,
    'helocExpert': helocExpert,
    'refiExpert': refiExpert,
  }

  const defaultImages = [
    "creditCard",
    "phone2",
    loByLoanType[typeName] || "mortgageExpert"
  ]

  const displayItems = items || [
    {
      text: "See where you are",
      subText: "We go beyond rates and show you where you're losing money every month.",
      image: "creditCard",
    },
    {
      text: "Get better options",
      subText: `We show you where you could be with a home loan that looks at much more.`,
      image: "phone2",
    },
    {
      text: "Stay on track",
      subText: "Our experts will guide you every step of the way so you are not in this alone.",
      image: loByLoanType[typeName] || "mortgageExpert",
    }
  ]

  return (
    <div css={styles.outer}>
      <h2 css={themeStyles.sectionHeader}>
        {title}
      </h2>
      <div css={styles.items}>
        {displayItems.map((item, index) => (
          <Item
            key={index}
            title={item.text}
            description={item.subText}
            image={images[item.image || defaultImages[index]]}
          />
        ))}
      </div>
    </div>
  )
}

export default LoanSnapDifference