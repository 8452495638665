import React from 'react'
import { themeStyles, colors, presets } from '../../utils/theme'
import operation from '../../img/icons/operation.svg'

const styles = {
  imageWithTextHolder: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    color: colors.darkGreen,
    marginBottom: '20px',
  },
  iconRow: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: '100%',
    marginBottom: '30px',
    maxWidth: '540px',
    [presets.Tablet]: {
      flexDirection: 'row',
    }
  },
  iconHolder: {
    backgroundColor: colors.tan,
    marginRight: '30px',
    borderRadius: '10px',
    padding: '30px',
    width: '100%',
    maxWidth: '190px',
    marginBottom: '20px',
    [presets.Tablet]: {
      marginBottom: '0px',
      maxWidth: '310px',
    }
  },
  icon: {
    width: '330px',
    margin: 0,
  },  
}

const VASection = ({productSectionHeader, productSectionDescription1, explainers}) => (
  <React.Fragment>
    <h2 css={themeStyles.sectionHeader}>
      {productSectionHeader}
    </h2>

    <p css={{marginBottom: '20px', maxWidth: '540px'}}>
      {productSectionDescription1}
    </p>
    <div css={styles.iconRow}>
      <div css={styles.iconHolder}>
        <img src={operation} css={styles.icon} alt='Operation Home'/>
      </div>
      <div>
        <p css={{ textAlign: 'left' }}>
          {explainers && explainers[0].text}
          <br/>
          <a css={{color: colors.blueGreen, textDecoration: 'underline'}} href="https://www.loansnap.org">
            Learn more
          </a>
        </p>
      </div>
    </div>
  </React.Fragment>
)

export default VASection