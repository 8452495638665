import React from 'react'
import { themeStyles, colors } from '../../utils/theme'
import cc from '../../img/icons/cc.svg'
import hand from '../../img/icons/hand.svg'
import brush from '../../img/icons/brush.svg'

const styles = {
  imageWithTextHolder: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    color: colors.blueGreen,
    fontWeight: 600,
    marginBottom: '20px',
  },
  iconHolder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: colors.tan,
    borderRadius: '100%',
    width: '90px',
    height: '90px',
  },
  icon: {
    width: '50px',
    margin: 0,
  },
  bulletHolder: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  bulletText: {
    marginLeft: '20px',
  },
}

const ImageWithText = ({ image, text }) => (
    <div css={styles.imageWithTextHolder}>
      <div css={styles.iconHolder}>
        <img src={image} css={styles.icon} alt=''/>
      </div>
      <div css={styles.bulletText}>
        {text}
      </div>
    </div>
)

const helocExplainersIcons = [ brush, cc, hand ]

const HelocSection = ({productSectionHeader, productSectionDescription1, productSectionDescription2, explainers}) => (
  <React.Fragment>
    <h2 css={themeStyles.sectionHeader}>
      {productSectionHeader}
    </h2>
    <p css={{ color: colors.grey }}>
      {productSectionDescription1}
    </p>
    <p css={{ color: colors.grey }}>
    {productSectionDescription2}
    </p>
    { explainers && 
      <div css={styles.bulletHolder}>
        {explainers.map((item, index) => (
          <ImageWithText
            key={index}
            image={helocExplainersIcons[index]}
            text={item.text}
          />
        ))}
      </div>
    }
  </React.Fragment>
)

export default HelocSection