import React from 'react'
import { themeStyles, colors, presets } from '../../utils/theme'
import arrow from '../../img/icons/arrows_REFI_desktop.svg'

const styles = {
  iconRow: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginBottom: '30px',
    [presets.Mobile]: {
      flexDirection: 'row',
    }
  },
  iconHolder: {
    backgroundColor: colors.tan,
    textAlign: 'left',
    width: '100%',
    maxWidth: '500px',
    minHeight: '200px',
    padding: '30px',
    marginBottom: '40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10px',
    [presets.Mobile]: {
      marginLeft: '20px',
      marginRight: '20px',
    }
  },
  arrow: {
    width: '100px',
    display: 'none',
    [presets.Mobile]: {
      display: 'block',
    }
  },
  refiTypeTitle: {
    color: colors.blueGreen,
    textAlign: 'left',
    fontWeight: 300,
  },
}

const RefinanceSection = ({productSectionHeader, explainers}) => (
  <React.Fragment>
    <h2 css={themeStyles.sectionHeader}>
      {productSectionHeader}
    </h2>
    <div css={styles.iconRow}>
      <div css={styles.iconHolder}>
        <h3 css={styles.refiTypeTitle}>
          {explainers[0].text}
        </h3>
        <p>
          {explainers[0].subText}
        </p>
      </div>
      <img src={arrow} css={styles.arrow} alt='Arrow'/>
      <div css={styles.iconHolder}>
        <h3 css={styles.refiTypeTitle}>
          {explainers[1].text}
        </h3>
        <p>
          {explainers[1].subText}
        </p>
      </div>
    </div>
  </React.Fragment>
)

export default RefinanceSection