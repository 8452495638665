import React from 'react'
import Button from '../components/Button'
import { colors } from '../utils/theme'

const TextAndCTA = ({ text, buttonText, link }) => (
  <React.Fragment>
    <h4 css={{ color: colors.blueGreen, fontSize: '24px', fontWeight: 400, maxWidth: '500px'}}>
      {text || "Answer a few questions to see what options we have for you."}
    </h4>
    <div css={{ marginTop: '10px' }} />
    <Button
      text={buttonText || "Get a Mortgage"}
      href={link || "https://secure.goloansnap.com"}
    />
  </React.Fragment>
)

export default TextAndCTA
